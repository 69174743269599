import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { Layout, Slider } from '../components'
import { container, padding, colours, bgImage, wrapper, type, pagePaddingTop, introText } from '../styles/global'
import { parseACF } from '../utils'
import { media } from '../styles/utils'

const ValueOfDesign = (props) => {
    const data = props.previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')

    console.log('data', data);
			
	const renderSlider = () => {
		if (!data.carousel_display || !data.carousel_items) return;

		const slides = data.carousel_items.map((item) => {
			item.use_caption = true;
			item.caption = item.caption || '<p> </p>';
		})

		return (
			<SliderWrapper>
				<Slider
					slides={data.carousel_items}
					sliderStyles={sliderStyles}
                    hoverCursors
                    hoverCaption
				/>
			</SliderWrapper>
		)
	}	

	const renderText = () => {
		if (!data.introduction) return
		
		return (
			<Intro>
				<Text
					dangerouslySetInnerHTML={{__html: data.introduction}}
				/>
			</Intro>
		)
	}
	
	const renderTextColumns = () => {
		if (!data.text_content) return
		
		return (
			<TextColumns>
				<Text
					dangerouslySetInnerHTML={{__html: data.text_content}}
				/>
			</TextColumns>
		)
	}
	
	return (
		<Layout
			meta={data.seo}
			breadcrumb={data.title}
        >
			<Container>
				{renderText()}
                {renderSlider()}
				{renderTextColumns()}
			</Container>
		</Layout>	
	)
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Text = styled.div``
const Info = styled.div``

const Image = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
	${container}
	${padding}
    ${pagePaddingTop}
	flex-direction: column;
    align-items: center;
	height: 100%;
    padding-bottom: 160px;

	${media.phone`
		padding-bottom: 50px;
	`}
`

// Intro

const Intro = styled.div`
	${introText}
`

// Slider

const SliderWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 100%;
	width: 100%;
`

const sliderStyles = css`
    width: 100%;
	height: 37vw;

	${media.phone`
		height: 256px;
		max-width: 100%;
	`}
	
	.slide {
		justify-content: center;
		overflow: visible;

		.image-wrapper {
			/* background-color: red; */

			.caption {
				position: absolute;
				bottom: -16px;
				transform: translateY(100%);
				
				${media.phone`
					width: 100%;
				`}
			}

			img {	
				width: auto;
				height: 100%;
				min-height: 37vw;
				object-fit: contain;
				object-position: left;
				max-width: 50vw;

				${media.phone`
					min-height: 256px;
					max-width: 100%;
				`}
			}
		}
	}
`

// Text Columns

const TextColumns = styled.div`
	margin-top: 186px;
	column-count: 2;	

	${Text} {
		column-gap: 43px;
	}

	${media.phone`
		column-count: 1;
		margin-top: 80px;

        &, p, a {
            ${type.bodySmall};
        }
    `}
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "the-value-of-design" } }) {
            nodes {
                title
                acf_json
            }
        }
    }
`


export default ValueOfDesign